<template>
  <div class="header-container">
    <div
      :style="{
        visibility: isSubbranch ? 'hidden' : 'visible',
      }"
      class="box"
    >
      <div class="title">
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <img src="@/assets/screen/bj/icon_title.png" alt="icon_title" />
          <div class="page-title">金融数字员工大屏</div>
          <img
            src="@/assets/screen/bj/icon_title_right.png"
            alt="icon_title_right"
          />
        </div>
      </div>

      <v-icon
        class="full-btn"
        :title="isFull ? '退出全屏' : '进入全屏'"
        @click="$bus.$emit('screen-full')"
        >{{ isFull ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  //组件注册
  components: {},
  inject: ['pageTabs'],
  model: {
    prop: 'value',
    event: 'set:value',
  },
  //组件传值
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isFull: false,
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
    document.addEventListener('fullscreenchange', this.fullscreenchange);
  },
  destroyed() {
    document.removeEventListener('fullscreenchange', this.fullscreenchange);
  },
  //保存方法
  methods: {
    handleEmit(e) {
      if (e.disabled) return;
      this.$emit('set:value', e.value);
    },
    fullscreenchange() {
      const isFull =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      this.isFull = Boolean(isFull);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  flex: 0 0 68px;
  display: flex;
  padding: 0 18px;
  background-image: url('../../../assets/screen/bj/topbar.png');
  background-size: 100% 100%;
  overflow: hidden;

  .box {
    flex: 1;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      padding: 0 52px 14px;
      overflow: hidden;
      position: relative;
      background: url('../../../assets/screen/bj/title_shape.png');
      background-size: 100% 100%;
      // text-shadow: 0px 3px 4px rgba(4, 36, 83, 0.6);

      .page-title {
        font-size: 36px;
        font-weight: bold;
        white-space: nowrap;
        background: linear-gradient(0deg, #8dd8ff 0%, #ffffff 60.009765625%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 12px;
      }
    }

    .full-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 90%;
      margin: auto;
      color: rgb(238, 249, 255);
      cursor: pointer;
      font-size: 32px;
      z-index: 10;

      &::after {
        background-color: transparent;
      }
    }
  }
}
</style>
